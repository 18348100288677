

.m-bibi-01 {
    width: 100%;
    display: flex;
    flex-grow: 1;
    min-height: 500px;
    background-image: url('../../../assets/images/m-bibitrade-01.svg');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.m-bibi-02 {
    width: 100%;
    display: flex;
    flex-grow: 1;
    min-height: 500px;
    background-image: url('../../../assets/images/m-bibitrade-02.svg');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.m-bibi-03 {
    width: 100%;
    display: flex;
    flex-grow: 1;
    min-height: 500px;
    background-image: url('../../../assets/images/m-bibitrade-03.svg');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}