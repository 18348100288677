

.career-item-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 32px;
    padding: 29px 33px 34px;
    background: #fff;
    -webkit-box-shadow: 0 12px 54px rgba(30,52,92,.058);
    box-shadow: 0 12px 54px rgba(30,52,92,.058);
    border-radius: 16px;
    transition: all 300ms ease-in-out;

    h2 {
        text-transform: capitalize;
        font-weight: 700;
        padding: 10px 0;
    }

    .career-category{
        background: #fff3d7;
        display: flex;
        width: 150px;
        min-width: 116px;
        padding: 9px 8px 10px 8px;
        border-radius: 10px;
        text-align: center;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        height: 40px;
    }

    .career-location {
        display: flex;
        flex-direction: row;
        align-items: center;

        i {
            color: #44AA95;
            padding-right: 10px;
            font-size: 20px;
        }

    }

    &:hover {
        background-color: #fef3d782;
    }
}



.apply-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 42px;
    font-family: BlinkMacSystemFont,-apple-system,Roboto,Arial,sans-serif;
    letter-spacing: -0.0438rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
    cursor: pointer;
    text-decoration: none;
    border-radius: 12px;
    outline: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    text-align: center;
    color: #44AA95;
    border: 2px solid #44AA95;
    background-color: transparent;
    -webkit-transition: background .2s,color .2s,opacity .2s;
    -o-transition: background .2s,color .2s,opacity .2s;
    transition: background .2s,color .2s,opacity .2s;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden; 

    &:hover {
        background-color: #44AA95;
        color: #fff;
    }
}