 

 .mock-container {
    //  width: 100%; 
    //  padding: 25px;
    //  background-image: url('../../../assets/images/mock-container.svg');
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-size: contain;
    border: 5px solid #f8f8f8;
    border-radius: 25px;
    padding: 20px;
 }
 .mock-02 {
     background-image: url('../../../assets/images/bibitrade-01.gif');
     background-position: center;
     background-size: contain;
     width: 100%;
     height: 400px;
     background-repeat: no-repeat;
 }

  
 .mock-03 {
    background-image: url('../../../assets/images/bibitrade_02.gif');
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;

}

.color-primary {
    color: #44AA95!important;
}

.vector06 {
    background-image: url('../../../assets/images/vector06.svg');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    width: 100%;
    height: 190px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}



.offset {  
    box-shadow: 
      0.3em 0.3em 0 0 var(--color),
      inset 0.3em 0.3em 0 0 var(--color);
    
    &:hover,
    &:focus {
      box-shadow: 
        0 0 0 0 var(--hover),
        inset 6em 3.5em 0 0 var(--hover);
    }
  }