

.privacy-container {

    h3 {
        font-family: "PT Root UI",BlinkMacSystemFont,-apple-system,Roboto,Arial,sans-serif;
        color: #111d30;
        font-size: 1.25rem;
        line-height: 1.563rem;
        padding: 20px 0;
    }
    p {
        font-size: 1rem;
        line-height: 1.375rem;
        color: #000000;
       padding: 10px 0!important; 
        text-align: justify;
    }

    ul {
        padding: 20px 0;
        li {
            position: relative;
            padding-left: 5px;
            margin-top: 16px;
            color: #2c7567;
            font-weight: 700;
        }
    }
}


.original_link {
    color: #2c7567;
}