 .card {
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    min-height: 366px;
    width: 100%;
    flex-direction: row;
    background-color: transparent;
    border: none;
    user-select: none;
 }

 .card__filled:nth-child(2n) {
    -ms-flex-item-align: center;
    align-self: center;
 }

 .card__filled:nth-child(5n) {
   -ms-flex-item-align: end;
   align-self: flex-end;
}

 .swiper-mobile {
    display: none;
 }

 .swiper-desktop {
    display: flex;
 }

 ul {
    list-style: none;
    padding: 0;
    margin: 0;
 }

 .small-box {
    width: 100%;
    background-color: #44AA95;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;



    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 257px;
    flex: 0 1 257px;
    min-height: 199px;
    padding: 10px 24px;
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-shadow: 0px 5px 24px -3px rgba(37, 138, 255, .5);
    box-shadow: 0px 5px 24px -3px rgba(37, 138, 255, .5);
    margin-right: 15px;
 }


 .card__filled {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 257px;
    flex: 0 1 257px;
    min-height: 199px;
    padding: 10px 24px;
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex-item-align: start;
    align-self: flex-start;
    background: #44AA95;
    -webkit-box-shadow: 0px 5px 24px -3px rgba(68, 170, 149, .5);
    box-shadow: 0px 5px 24px -3px rgba(68, 170, 149, .5);
    margin-right: 15px;
 }

 .card__label {
    margin-top: 16px;
    margin-bottom: 0;
    font-family: BlinkMacSystemFont, -apple-system, Roboto, Arial, sans-serif;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
    color: #ffffff;
 }

 .text-medium-size {
    font-size: 1.125rem;
    line-height: 1.625rem;
 }

 .card__description {
    margin-top: 4px;
    line-height: 1.375rem;
    text-align: center;
    color: #ffffff;
 }

 .text-normal {
   font-size: 13px;
   line-height: 1rem;
 }

 .text-medium {
    font-weight: 500;
 }


 .swiper-icon {
    width: 50px;
    height: 50px;
    background-color: #58BCA8;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
       color: #ffffff;
       font-size: 30px;
    }
 }




 @media screen and (max-width:1020px) {
    .swiper-mobile {
      width: 100%; 
      display: flex;
      flex-grow: 1;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      overflow: hidden;
   
    } 

    .swiper-desktop {
       display: none;
    }

 }