

.swiper-container {
    width: 100%;
    height: 100%;
    /*this gives the dimension of slider container*/
    /*these values will make the container as big as the parent div*/
    overflow: hidden;
    /*important parameter to not disrupt the container when slides increases in coverflow and other effect*/
    }
    .swiper-slide {
    width:300px;
    height:300px;
    /*this one holds the dimension of each slides and can be changed according to the user wish*/
    }

    

    .btn {
        background-color: #ffffff;
        display: inline-flex;
        flex-direction: row;
        border-radius: 50px;
        min-width: 50px;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding: 2px 40px;
        transition: all 300ms ease-in-out;
        p {
            padding-right: 20px!important;
            font-weight: 700;
            user-select: none;
        }
    
        &:hover { 
            box-shadow: inset 16.5em 0 0 0 #44AA95;
            border-color: #44AA95;
            color: #fff;
        }
    }
    
    
    .btn-light {
        border: 1px solid #44AA95;
        color: #44AA95;
        
    }


    .text-bold {
        font-weight: 700;
    }