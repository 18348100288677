
.logo-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%!important;
}

.logo-beta {
    width: 150px;
    height: 50px;
    background-image: url('../../../assets/images/logo-bibitrade-beta.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}