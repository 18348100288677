.relative {
    position: relative;
}

.welcome-header {
    padding: 40px;
}

.welcome-text {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    /* max-width: 900px; */
    text-align: center;
    margin: 0 auto;
    padding: 62px 0 32px;
    height: 800px;



    h2 {
        font-size: 3rem;
        line-height: 3.5rem;
        font-family: "Gilroy-Heavy";

     }

    h1 {
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 700;
        font-family: "Gilroy-Heavy";

     }

    .main__text-container {
        max-width: 676px;
        text-align: center;
        margin: 0 auto;
        position: relative;
        padding-top: 20px;
    }
}

.w-mobile-app {
    width: 100%;
    margin-bottom: 100px;
    padding: 30px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .mobile-icon {
        width: 44px;
        height: 44px;
        margin: 0 10px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 20px;
        }

    }

    .open-account {
        display: inline-flex;
        padding: 12px 22px;
        font-family: BlinkMacSystemFont, -apple-system, Roboto, Arial, sans-serif;
        letter-spacing: -0.0438rem;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.25rem;
        cursor: pointer;
        text-decoration: none;
        border-radius: 50px;
        outline: none;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        white-space: nowrap;
        text-align: center;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        align-items: center;
        transition: all 300ms ease-in-out;
        margin: 0 10px;

        p {
            padding-right: 15px !important;
            font-weight: 500;
        }

        &:hover {
            opacity: 0.7;
            background-color: inherit;
        }

        i {
            font-size: 12px;
        }
    }
}

.shape-01 {
    background-image: url('../../../assets/images/shape-01.svg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    width: 500px;
    height: 500px;
    position: absolute;
    top: -220px;
    left: 0;
}

.small-box-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.w1-bibitrade {
    background-image: url('../../../assets/images/w1-bibitrade.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.wave-index {
    height: 600px;
    width: 100%;
    background-image: url('../../../assets/images/vector04.svg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 110%;
    background-color: transparent;
    display: flex;
    align-items: center
}

.xxx {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
}

.wallet-img {
    background-image: url('../../../assets/images/wallet.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;

}

.welcome-content {
    height: 700px;
    position: relative;
    padding-top: 150px;
}

.welcome-bg-color {
    background-color: transparent;
}

.primary-color {
    color: #186152 !important;
    font-weight: 700;
    text-shadow: 2px 2px 2px #2db79d;

}

.percent {
    font-size: 30px;
}



@media screen and (max-width: 1020px) {
    .w1-bibitrade {
        background-image: none;
    }

    .welcome-text {
        align-items: flex-start;
        text-align: left;
        justify-content: center;
        height: 500px;
        .main__text-container {
            text-align: left;
            margin: 0;
        }

        .w-mobile-app {
            margin: 0;
        }

    }

    .wave-index {
        align-items: flex-start;
        height: 27vh;
    }
}