.footer {
    padding: 80px 0;
}

.footer-title {
    color: #999999;
    font-weight: 700;
    padding-bottom: 10px;

}

.get-in-touch {
    list-style-type: none;
    text-align: center;
}

.social-network {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    a {
        width: 35px;
        height: 35px;
        margin: 5px;
    }
}

.footer-menu-item {
    padding-bottom: 5px;
    color: #1565c0;
    transition: all 300ms ease-in-out;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.border-line {
    border-top: 1px solid #DBDBDB;
}

.bibiTrade {
    font-size: 12px;
    color: #999999;
}

.social-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    transition: all 300ms ease-in-out;

    &:hover {
        opacity: 0.7;
    }

}

.icon-facebook {
    background-image: url('../../../assets/images/social/facebook.svg');
}

.icon-linkedin {
    background-image: url('../../../assets/images/social/linkedin.svg');
}

.icon-twitter {
    background-image: url('../../../assets/images/social/twitter.svg');
}

.icon-instagram {
    background-image: url('../../../assets/images/social/instagram.svg');
}