
.how-get-it-worked-item {

    -webkit-box-shadow: 0px 0px 47px 0px #44aa9626;
    box-shadow: 0px 0px 47px 0px #44aa9626;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #e5f2f075;
    padding: 80px 20px;
    margin: 10px; 
    background-color: #ffffff;

    h3{
        text-align: center; 
    }
    p {
        text-align: center;
        padding-top:10px; 
        color: #9A9A9A;
    }

}

.text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 0;

    .title-page {
        font-family: "Gilroy-Heavy";
        text-align: center;  
        font-weight: 900;
        line-height: 36px;
    }
    p {
        font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: rgb(51, 51, 51);
    }
    
}

.light-bg{
    background-color: #E5F2F0;
}

.how-get-it-worked-icon {
    width: 150px;
    height: 150px; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; 
}

.icon-api {
    background-image: url('../../../assets/images/api.svg');
    
}

.icon-setup {
    background-image: url('../../../assets/images/setup.svg');
    
}

.icon-auto-rebalanced {
    background-image: url('../../../assets/images/auto-rebalance.svg');
    
}

.shape-03 {
    background-image: url('../../../assets/images/shape-03.svg');
    background-position: 900px -200px;
    background-size: cover;
    background-repeat: no-repeat;
 
}