.personal-account {
    background-color: #E5F2F0;
    background-image: url('../../../assets/images/shape-02.svg');
    background-position: -500px -100px;
    background-repeat: no-repeat;
    background-size: contain;
}

.mock-01 {
    width: 100%;
    height: 500px;
    background-image: url('../../../assets/images/mock-01.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

}

.btn-sign-up {
    border-radius: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    padding: 10px;
    transition: all 300ms ease-in-out;
    text-decoration: none;
    background-color: #44AA95 !important;
    border: 1px solid transparent;
    color: #ffffff;
    height: 50px;

    &:hover {
        box-shadow: inset 16.5em 0 0 0 #ffffff !important;
        border-color: #44AA95;
        color: #44AA95;
    }

    p {
        text-decoration: none;
        font-weight: 700;
        padding-right: 15px !important;
    }

}



a {
    color: inherit;

    &:hover {
        text-decoration: none;

    }
}