.how-it-works-img { 
    height: 500px;
    width: 100%;
    background-image: url('../../../assets/images/how.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.rocket {
    background-image: url('../../../assets/images/rocket.svg');
    background-position: -470px -50px ;
    background-repeat: no-repeat;
    background-size: contain;
}

.h-700 {
    min-height: 100vh; 
    padding: 0;
    margin: 0;
}

.f8f8f8 {
    background-color: #F8F8F8;
}