.header-menu {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    list-style-type: none;
    margin: 0;

    .header-menu-item {
        margin: 0 10px;
        padding: 7px 10px;
        font-weight: 700;
        text-transform: capitalize;
        transition: all 300ms ease-in-out;
        color: #333333;
        display: flex;
        transition: all 300ms ease-in-out;

        &:hover {
            // opacity: 0.6;
            color: #44AA95;
        }
    }
}
.menuTablet {
    display: none;
}

.headerMenuDown {
    display: none;
}

.headerMenuUp {
    display: block;
}



.btn-login {
    border-radius: 150px; 
    padding: 7px 40px !important;
    font-weight: 700;
    text-transform: capitalize;
    transition: all 300ms ease-in-out;
    background-color: #44AA95; 
    color: #ffffff!important;


    border-radius: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
    transition: all 300ms ease-in-out;
    text-decoration: none;
    background-color: #44AA95 !important;
    border: 1px solid transparent;
    color: #ffffff; 



    &:hover {
        box-shadow: inset 16.5em 0 0 0 #ffffff !important;
        border-color: #44AA95;
        color: #44AA95!important;

    }
}

.mobile-menu-container {
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;

    .m-header {
        width: 100%; 
        display: flex;
        align-items: center;
        justify-content: space-between;

        .m-close {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            cursor: pointer;

        }

    }

    .m-body {
        display: flex;
        width: 100%;
        flex-grow: 1; 
        flex-direction: column;

        .m-menu { 
            flex-grow: 1;
            align-items: center;
            justify-content: center;
            display: flex;
            padding: 0 30px;

            ul {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;

                li {
                    width: 50px;
                    margin-right: 0;
                    padding: 20px 0;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    text-decoration: none;
                    cursor: pointer;
                    white-space: nowrap;
                    color: #111d30;
                    -webkit-transition: all .2s;
                    -o-transition: all .2s;
                    transition: all .2s;
                    font-size: 18px;
                    font-weight: 700;
                    user-select: none;
                    cursor: pointer;
                    min-width: 200px;

                    &:hover,
                    &:active,
                    &:focus {
                        color: #44aa96;
                        padding-left: 20px;
                    }

                }
            }

        }

        .m-footer { 
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            padding-bottom: 50px;

            ul {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                width: 500px;

                li {
                    width: 100%;
                    margin: 0 10px;
                }

                .m-header-menu-item {
                    display: inline-block;
                    padding: 12px 22px;
                    font-family: BlinkMacSystemFont, -apple-system, Roboto, Arial, sans-serif;
                    letter-spacing: -0.0438rem;
                    font-weight: 700;
                    font-size: 1rem;
                    line-height: 1.25rem;
                    cursor: pointer;
                    text-decoration: none;
                    border-radius: 12px;
                    outline: none;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    white-space: nowrap;
                    text-align: center;
                    color: #44aa96;
                    border: 2px solid #44aa96;
                    background-color: transparent;
                    -webkit-transition: background .2s, color .2s, opacity .2s;
                    -o-transition: background .2s, color .2s, opacity .2s;
                    transition: background .2s, color .2s, opacity .2s;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    overflow: hidden;

                }

                .m-btn {
                    border: 1px solid rgba(0, 0, 0, .1);
                    width: 100%;
                    padding: 12px 22px;
                }
            }
        }


    }
}


@media screen and (max-width:1000px) {
    .header-menu {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .header-menu-item {
        margin-top: 10px !important;

    }
}