.investing-image {
    width: 100%;
    display: flex;
    flex-grow: 1;
    min-height: 500px;
    background-image: url('../../../assets/images/investing-bibitrade.png');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.pt-100 {
    padding-top: 200px;
}

.earn-ul {
    padding: 30px 0;

    li {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        line-height: 2rem;


        span {
            padding-left: 15px;
            color: #111D30;
            font-weight: 700;
        }
        i {
            padding-top: 8px;
        }

    }
}

.bottom-vector { 
    background-image: url('../../../assets/images/vector05.svg');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    width: 100%;
    height: 190px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}

.c-container {
    position: relative;
    padding: 100px 0;
}