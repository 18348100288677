@import "~bootstrap/scss/bootstrap";

@font-face {
    font-family: "Gilroy-Regular";
    src: local("Gilroy-Regular"),
        url("./assets/fonts/circular/Gilroy-Medium.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Gilroy-Heavy";
    src: local("Gilroy-Heavy"),
        url("./assets/fonts/circular/Gilroy-Heavy.ttf") format("truetype");
    font-weight: bold;
}



html {
    background-color: #F0F4F9;
}

body {
    // font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-family:"Gilroy-Regular";
    font-size: 16px;
    color: rgb(51, 51, 51);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    margin: 0px;
}

h1 {
    font-family:"Gilroy-Heavy";
    font-weight: 700;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

p {
    padding: 0 !important;
    margin: 0 !important;
}

.form-title {
    padding-top: 20px;
}

.form-container {
    padding: 30px 20px;
    position: relative;
}

.bg-cover {
    // background: rgba(237, 247, 245, 0.486);
    // background: linear-gradient(29deg, rgba(237, 247, 245, 0.685) 0%, rgba(205, 223, 220, 0.712) 100%);
    background: linear-gradient(25deg, #4ec3ac 5%, #308f7c 20%, #4f9e8e 30%, #3cd1b3 50%, #44AA95 100%, #44AA95 50%);
    background-size: 400% 400%;
    animation: gradient 20s ease infinite;
}


@keyframes gradient {
    0% {
        background-position: 0 92%;
    }

    50% {
        background-position: 100% 9%;
    }

    100% {
        background-position: 0 92%;
    }
}


.bg-primary {
    background-color: #44AA95 !important;
    color: #ffffff !important;
}

.color-primary {
    color: #44AA95 !important;
}