 
 .ready-to-join {
     background-color: #F5F5F5;
 }


 .world {
     background-image: url('../../../assets/images/map-bg.svg');
     background-position: center;
     background-size: 100%;
     background-repeat: no-repeat;
 }

 .txt-center {
     text-align: center;
     
 }