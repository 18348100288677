.navbar {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  transition: all .2s ease-in;
  position: relative;
  z-index: 99;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px)!important;

}

//IMPORTANT
.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  height: 66px;
  background-color: rgba(255, 255, 255, 0.8); 
}

.header-menu-humbugger {
  display: none;
  width: 50px;
  height: 50px;

  i {
    font-size: 22px;
  }
}

.logo img {
  width: 50px;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;

}


@media screen and (max-width:1020px) {
  .header-menu {
    display: none;
  }

  .header-menu-humbugger {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}