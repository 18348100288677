.earn-smarter-image { 
    width: 100%;
    display: flex;
    flex-grow: 1;
    min-height: 500px;
    background-image: url('../../../assets/images/earnSmarter-bibitrade.png');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}
.pt-100 {
    padding-top: 200px;
}

.earn-ul {
    padding: 30px 0;

    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 2rem;


        span {
            padding-left: 15px;
            color: #111D30;
            font-weight: 700;
        }
       
    }
}