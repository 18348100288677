.headUp-container {
    background-color: #f8f8f8;
    position: relative;
    height: 400px;


    .headUp-shape {
        width: 100%;
        position: absolute;
        height: 150px;
        background-color: transparent;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url('../../../assets/images/vector07.svg');
        background-position: top;
        background-repeat: no-repeat;
        background-size: 200%;

    }

    .headUp-text {
        text-align: center;
        padding-top: 40px;

        h1 {
            font-size: 60px!important;
            span {
                color: #44AA95;
            }
        }
    }
}