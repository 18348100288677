.rating-img {
    width: 100%;
    height: 150px; 
    background-image: url('../../../assets/images//rating-logos.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}



.light-blue {
    background-color: rgb(246, 249, 252);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-left: none;
    border-right: none;

}